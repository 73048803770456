
h1{
    font-size: 48px;
    font-weight: 800;
    margin-top: 40px;
}


.headparaMargin{
    margin-bottom: 1.5rem;
}
.headPara_wrapper p{
font-size: 24px;
color: rgb(90, 90, 90);
}

@media only screen and (max-width:960px){
   .headPara_wrapper{
    text-align: center;
   }
}


@media only screen and (max-width:600px){
    .headPara_wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .headparaMargin{
        margin-bottom: 1rem;
    }
}

@media only screen and (max-width:580px){
    .headPara_wrapper{
       h1{
        font-size: 35px;
        margin-top: 20px;
       }
       p{
        font-size: 20px;
        margin-top: 5px;
       }
    }
  
}

