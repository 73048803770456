@font-face {
    font-family: 'VilakaModernSerif';
    src: url('./assets/font/Vilaka\ Modern\ Serif\ Font.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  

.hero-div {
    background-image: url(assets/SAM2.jpg);
    box-shadow: 10174px 4px 4px 0px rgba(0, 0, 0, 0.55) inset;
    background-position: center -600px;
}

.header {
    color: white;
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    height: 90px;
    z-index: 100;
}

.nav-item:hover {
    color: rgb(161, 158, 158);
}

.nav-item.active {
    border-bottom: 2px solid white;
}

.navm {
    background-color: rgb(32, 31, 31);
    ;
}

.header-bg {
    background-color: rgb(32, 31, 31);
}

.pic1 {
    background-image: url('./assets/SAM1.jpg');
    background-position: center;
    background-size: cover;
}

.pic2 {
    background-image: url('./assets/SAM10.jpg');
    background-position: center;
    background-size: cover;
}

.pic3 {
    background-image: url('./assets/SAM16.jpg');
    background-position: center;
    background-size: cover;
}

.pic4 {
    background-image: url('./assets/SAM20.jpg');
    background-position: center;
    background-size: cover;
}

.adiv {
    border-radius: 4px;
    background-image: linear-gradient(rgba(54, 52, 52, 0.75), rgba(41, 40, 40, 0.75)), url('./assets/landing.jpg');
    background-position: center;
    background-size: cover;
    box-shadow: rgba(0, 0, 0, 0.25) inset;
}

.bdiv {
    border-radius: 4px;
    background-image: linear-gradient(rgba(54, 52, 52, 0.75), rgba(41, 40, 40, 0.75)), url('./assets/img\ 2.png');
    background-position: center;
    background-size: cover;
    box-shadow: rgba(0, 0, 0, 0.25) inset;
}

.cdiv {
    border-radius: 4px;
    background-image: linear-gradient(rgba(54, 52, 52, 0.75), rgba(41, 40, 40, 0.75)), url('./assets/pictureperfectmoment.jpg');
    background-position: top center;
    background-size: cover;

    box-shadow: rgba(0, 0, 0, 0.25) inset;
}

.ddiv {
    border-radius: 4px;
    background-image: linear-gradient(rgba(54, 52, 52, 0.75), rgba(41, 40, 40, 0.75)), url('./assets/img\ 4.png');
    background-position: center;
    background-size: cover;
    box-shadow: rgba(0, 0, 0, 0.25) inset;
}

.ediv {
    border-radius: 4px;
    background-image: linear-gradient(rgba(54, 52, 52, 0.75), rgba(41, 40, 40, 0.75)), url('./assets/img\ 5.png');
    background-position: center;
    background-size: cover;
    box-shadow: rgba(0, 0, 0, 0.25) inset;
}

.fdiv {
    border-radius: 4px;
    background-image: linear-gradient(rgba(54, 52, 52, 0.75), rgba(41, 40, 40, 0.75)), url('./assets/img\ 6.png');
    background-position: center;
    background-size: cover;
    box-shadow: rgba(0, 0, 0, 0.25) inset;
}


.form {
    background-color: #F5F5F5;
}

.btn {
    background-color: #2E2E2E;
}

.btn:hover {
    background-color: #000000;
}

.footer {
    background-color: #2E2E2E;

}


.container {
    max-width: 3000px;
    display: grid;
    width: 100%;
    height: 1500px;
    grid-gap: 15px;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 100px 100px 100px 100px;
    grid-template-areas: "img1 img3 img6"
        "img1 img3 img6"
        "img1 img3 img6"
        "img1 img4 img6"
        "img1 img4 img7"
        "img1 img4 img7"
        "img1 img4 img7"
        "img1 img4 img7"
        "img1 img4 img7"
        "img1 img4 img7"
        "img2 img4 img7"
        "img2 img5 img7"
        "img2 img5 img8"
        "img2 img5 img8"
        "img2 img5 img8"
        "img2 img5 img8"
        "img2 img5 img8"
        "img2 img5 img8"
        "img2 img5 img8"
    ;
}

.container .img1 {
    grid-area: img1;
    background-image: url(assets/SAM4.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.container .img2 {
    grid-area: img2;
    background-image: url(assets/SAM11.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.container .img3 {
    grid-area: img3;
    background-image: url(assets/SAM17.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.container .img4 {
    grid-area: img4;
    background-image: url(assets/SAM19.jpg);
    background-position:center ;
    background-size: cover;
    background-repeat: no-repeat;
}

.container .img5 {
    grid-area: img5;
    background-image: url(assets/SAM8.jpg);

    background-position: center ;
    background-size: cover;
    background-repeat: no-repeat;
}

.container .img6 {
    grid-area: img6;
    background-image: url(assets/SAM21.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.container .img7 {
    grid-area: img7;
    background-image: url(assets/SAM14.jpg);
  
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.container .img8 {
    grid-area: img8;
    background-image: url(assets/SAM5.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}



@media (max-width:1470px) {
    .hero-div{
        background-position: center -300px;
    }
    .container{
   
        padding-right: 15px;
    }
    .seconddiv {
        padding: 60px;
    }
    
    .kmdiv {
        margin: auto;
        width: 100%;
        margin-top: 20px;
    }

    .adiv,
    .bdiv,
    .cdiv,
    .ddiv,
    .ediv,
    .fdiv {
        height: 380px;
    }

}

@media (max-width:1310px) {

    .adiv,
    .bdiv,
    .cdiv,
    .ddiv,
    .ediv,
    .fdiv {
        height: 450px;
    }

}

@media (max-width:1135px) {

    .adiv,
    .bdiv,
    .cdiv,
    .ddiv,
    .ediv,
    .fdiv {
        height: 490px;
    }

}

@media (max-width:1060px) {

    .adiv,
    .bdiv,
    .cdiv,
    .ddiv,
    .ediv,
    .fdiv {
        height: 520px;
    }

}

@media (max-width:1030px) {
    .container{
   
        padding-right: 13px;
    }
    .aboutdiv {
        gap: 20px;
    }
}

@media (max-width:960px) {
    .hero-div{
        background-position: center ;
    }
    .container {
        margin: auto;
        display: grid;
        width: 100%;
        height: 900px;
        grid-gap: 15px;
        grid-template-columns: 50% 50% ;
        grid-template-rows: 10% 10% 10% 10%;
        grid-template-areas: "img1 img3 "
        "img1 img3 "
        "img2 img3 "
        "img2 img4 "
        "img2 img4 "
        "img6 img4 "
        "img6 img4 "
        "img6 img4 "
        "img6 img4 "
        "img6 img4 "
        "img6 img4 "
        "img6 img4 "
        "img6 img4 "
        "img6 img5 "
        "img6 img5 "
        "img6 img5 "
        "img6 img5 "
        "img7 img5 "
        "img7 img5 "
        "img7 img5 "
        "img7 img5 "
        "img7 img5 "
        "img7 img5 "
        "img7 img5 "
        "img7 img5 "
        "img7 img5 "
        "img7 img8 "
        "img7 img8 "
        "img7 img8 "
        "img7 img8 "
        "img7 img8 "
        "img7 img8 "
        "img7 img8 "
        "img7 img8 "
        "img7 img8 "
        "img7 img8 "
        "img7 img8 "
        "img7 img8 "
        "img7 img8 "
        "img7 img8 "
        "img7 img8 "
        "img7 img8 "


        ;
        
    }
    
    .picdiv {
        margin: auto;
    }

    .textdiv {
        margin: auto;
        margin-top: 60px;
        text-align: center;
    }

    .adiv,
    .bdiv,
    .cdiv,
    .ddiv,
    .ediv,
    .fdiv {
        margin: auto;
        width: 100%;
        margin-top: 20px;
        height: 340px;
    }

    .kmdiv {
        margin: auto;
        width: 100%;
        margin-top: 20px;
    }

    .testidiv {
        padding-bottom: 10px;
    }

    .film-p {
        margin-left: 20px;
    }

    .contact-div {
        width: fit-content;
        margin: auto;
    }
}

@media (max-width:615px) {
.container{
   
    padding-right: 13px;
}

    .seconddiv {
        padding: 20px;
    }

}

@media (max-width:540px) {
   

    .textdiv {
        margin-top: 20px;
    }

}

@media (max-width:430px) {
   

    .adiv,
    .bdiv,
    .cdiv,
    .ddiv,
    .ediv,
    .fdiv {
        height: 400px;
    }
}

@media (max-width:375px) {
   

    .adiv,
    .bdiv,
    .cdiv,
    .ddiv,
    .ediv,
    .fdiv {
        height: 430px;
    }
}

@media (max-width:320px) {
   

    .adiv,
    .bdiv,
    .cdiv,
    .ddiv,
    .ediv,
    .fdiv {
        height: 530px;
    }
}

@media (max-width:310px) {

    .pic1,
    .pic4 {
        width: 110px;
    }
}