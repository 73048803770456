.testimonial_container {
    display: flex;
    width: 100%;
    height: 100%;
   
    flex-direction: column;
    gap: 1.5rem;
    position: relative;
    .card-wrpr {
      padding-left: 4rem;
    }
  
    .prev,
    .next {
      position: absolute;
      z-index: 4;
      top: 60%;
      transform: translateY(-50%);
    }
  
    .card-wrpr_r {
      padding-right: 4rem;
    }
    .swiper-button-next {
      color: #000 !important;
    }
    .swiper-button-prev {
      color: #000 !important;
    }
  
    svg {
      font-size: 3rem;
    }
  }
  
  .prev {
    left: -4rem;
  }
  
  .next {
    right: -4rem;
  }


  
  @media only screen and (max-width: 1000px) {
    .testimonial_container {
      padding: 1rem 3rem;
  
      .prev {
        left: 0rem;
      }
      .next {
        right: 0rem;
      }
    }
  }
  
  
  
  @media only screen and (max-width: 700px) {
    .testimonial_container {
      padding: 1rem 3rem;
      svg {
        font-size: 2.5rem;
      }
      .prev, .next {
        display: none;
     
      }
      
    }
  }
  
  @media only screen and (max-width: 500px) {
    .testimonial_container {
      padding: 1.8rem;
      svg {
        font-size: 2.3rem;
      }
    
    }
  }
  
  
  
  